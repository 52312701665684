<template>
     <div class="body">
          <div class="container">
               <h1>Hello! from Team CTL. <br />We are empowering the future.</h1>
               <p class="x-large">
                    25+ years of Experience + Pioneers of Green and Global Technology + Strategic Leadership + Quality Assurance International
                    Standards + Dynamic Manpower + Globally Certified + Client Centric and Customer Focused + Customised Expertise
               </p>

               <div class="columns details  ">
                    <div class="column is-half">
                         <div class="line-top">
                              <div class="section-title">
                                   CTL Intentions For<br />
                                   Every Collaboration
                              </div>
                              <p>
                                   We are using technology to transform the world by designing efficiently and responsibly for businesses that want to
                                   enhance their customer experience and expand their brand offerings.
                              </p>
                              <p>
                                   We meet you halfway with innovation and deliver researched, customised solutions as per your business requirements.
                              </p>
                              <p>
                                   We are certified by major OEMs and global standards of data centre certifications.
                              </p>
                              <p>
                                   We design with quality and deliver with a promise of effectiveness.
                              </p>
                              <p>
                                   Our customer support extends to hyper local, local, national and international locations and we are available to
                                   handle your queries.
                              </p>
                         </div>
                    </div>
                    <div class="column is-half">
                         <div class="line-top">
                              <div class="section-title">
                                   About
                              </div>
                              <p>
                                   The CTL culture is deeply rooted in collaboration for a successful future for your business, learning to excel and
                                   inspiring through example. The effervescent leadership inspires by upholding a vision for a future with intelligent
                                   infrastructure underlined with sustainability.
                              </p>
                              <p>
                                   CTL instituted in 1994, is an ISO 9001 -2015 Certified Organisation. With initial operations in the telecom
                                   industry for MTNL in Mumbai, it made its foray into the Information Technology segment for providing various IT
                                   infrastructure solutions. Since 2004, CTL has successfully rendered influential collaborations for Data Center
                                   solutions, Electronic Security surveillance, Building Management Solutions, Audio and Video solutions, Artificial
                                   Intelligence, IOT, etc. Competitive and equipped with efficient and agile service models, the CTL team has evolved
                                   to a highly competitive player in the Global IT solution market.
                              </p>
                         </div>
                    </div>
               </div>

               <div class="history ">
                    <div class="columns mb-1">
                         <div class="column"></div>
                    </div>

                    <div class="columns  mt-4 ">
                         <div class="column is-half">
                              <div class="columns">
                                   <div class="column is-2">
                                        <div class="datefrom">1994</div>
                                        <div class="dateto">1996</div>
                                   </div>
                                   <div class="column is-10">
                                        <p>CTL was established in Mumbai to provide service support for HCL & MTNL.</p>
                                        <p>
                                             While successfully securing landmark projects like Mahindra and Mahindra. Business standards, BMC, ORG
                                             Marg, L&T Powai, SCICI, Glenmark and the Mumbai Stock exchange.
                                        </p>
                                   </div>
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="columns">
                                   <div class="column is-2">
                                        <div class="datefrom">1997</div>
                                        <div class="dateto">2001</div>
                                   </div>
                                   <div class="column is-10">
                                        <p>
                                             Becomes the Certified System Installer of R&M for providing structured cabling solutions. Within these 4
                                             years CTL concluded more than 100 significant corporate projects like HDFC, MHADA, Air India, Lufthansa,
                                             St. George Hospital, Camay and GT Hospital Mumbai, etc.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div class="columns mt-4  ">
                         <div class="column is-half">
                              <div class="columns">
                                   <div class="column is-2">
                                        <div class="datefrom">2002</div>
                                        <div class="dateto">2009</div>
                                   </div>
                                   <div class="column is-10">
                                        <p>
                                             The new decade calls for a new office in South India - Kochi. Completed 700 Branches Networking for South
                                             Indian Bank. Our experience and service models earn us the trust and helps us make a huge foray into the
                                             Banking sector with services to Federal Bank, Union Bank, Canara Bank, OICL, UT Computer Labs, etc.
                                        </p>
                                        <p>
                                             Also received ISO Certification. Got certified as Master installer for AMP, Krone, Systimax, etc.
                                        </p>
                                   </div>
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="columns">
                                   <div class="column is-2">
                                        <div class="datefrom">2010</div>
                                        <div class="dateto">2015</div>
                                   </div>
                                   <div class="column is-10">
                                        <p>
                                             CTL gets certified as CDCP (Certified Data Center professional), CDCS, CDCE for providing Data Center
                                             solutions.
                                        </p>
                                        <p>
                                             Data centre Design and development for V-Guard, HOCL, CIAL, Hedge, Aster Med city, Joy Mall etc. Acquires
                                             training from industry leads like HP, Cisco, Alcatel, Avaya, etc. Opening of the third CTL office in the
                                             IT capital of India - Bengaluru. Prestigious and large scale projects like  Lulu Mall and Aster Med city
                                             are successfully handed over.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div class="columns mt-4 ">
                         <div class="column is-half">
                              <div class="columns">
                                   <div class="column is-2">
                                        <div class="datefrom">2016</div>
                                        <div class="dateto">2018</div>
                                   </div>
                                   <div class="column is-10">
                                        <p>
                                             Successfully completes expansive projects like City Marriott, Grand Hyatt Hotel,  MVR Cancer Center,
                                             Rajagiri Hospital, VPS Lakeshore, Cochin Shipyard, Hyatt Regency, Y mall. Got ISO Certification in IT
                                             Infra solutions.
                                        </p>
                                        <p>
                                             A major achievement in CTL Consultancy - as we get selected as an IT & ELV consultant for the Kannur
                                             Airport.
                                        </p>
                                   </div>
                              </div>
                         </div>
                         <div class="column is-half">
                              <div class="columns">
                                   <div class="column is-2">
                                        <div class="datefrom">2019</div>
                                        <div class="dateto">2020</div>
                                   </div>

                                   <div class="column is-10">
                                        <p>
                                             Built Data Center for LPSE (ISRO), Marsleeva Hospital and Welcare Hospital. Got awarded Taj Bansura Hotel
                                             Project, Q1 Mall, MVD Kerala, Petronet LNG, PWD project, Lulu Convention Center project,  etc.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div class="quote ">
                    Successful alliance with <span class="green">2000+ </span>clients and empowering <span class="green">5 Million </span> users.
               </div>

               <div class="careers ">
                    <div class="section-title">
                         Careers
                    </div>
                    <p class="large light">
                         We are always looking out for enthusiastic talent to

                         <router-link :to="{ name: 'COMINGSOON' }" target="_blank"> <span class="green">join our team</span> </router-link>.
                    </p>
               </div>
          </div>
     </div>
</template>

<script>
     import { Hooper, Slide } from "hooper";
     import "hooper/dist/hooper.css";
     export default {
          data() {
               return {
                    hooper: {
                         slideNo: 0,
                         isPrevBtnVisible: false,
                         isNextBtnVisible: true,
                    },
               };
          },
          components: {
               Hooper,
               Slide,
          },
          methods: {
               hooperSlide(data) {
                    this.hooper.slideNo = data.currentSlide;
                    if (this.hooper.slideNo > 0) {
                         this.hooper.isPrevBtnVisible = true;
                    } else {
                         this.hooper.isPrevBtnVisible = false;
                    }

                    if (this.hooper.slideNo == 2) {
                         this.hooper.isNextBtnVisible = false;
                    } else {
                         this.hooper.isNextBtnVisible = true;
                    }
               },
               hooperPrev() {
                    this.$refs.hooper.slidePrev();
               },
               hooperNext() {
                    this.$refs.hooper.slideNext();
               },
          },
     };
</script>

<style lang="scss" scoped>
     .body {
          padding-top: 50px;
          padding-bottom: 100px;
     }
     h1 {
          margin-bottom: 10px;
     }
     .sideimg {
          width: 100%;
     }
     .column {
          padding-right: 80px;
          @media (max-width: 1023px) {
               padding-right: 0;
          }
     }
     .section-title {
          margin-top: 20px;
          margin-bottom: 30px;
     }
     p {
          padding-bottom: 30px;
     }
     p.x-large {
          padding-top: 30px;
     }
     .careers {
          margin: 70px 0 0px;
          @media (max-width: 1023px) {
               margin: 60px 0 40px;
          }
          p {
               padding-top: 0px;
               font-weight: 300;
               font-size: 24px;
          }
          .section-title {
               margin-bottom: 10px;
          }
     }
     .details {
          padding-top: 70px;
          margin-bottom: -30px;
     }
     .history {
          margin-top: 10px;
          margin-bottom: 5px;

          @media (max-width: 1023px) {
               margin-top: 10px;
               margin-bottom: 10px;
          }
     }
     .quote {
          padding: 5px 0 0px;
     }
</style>
